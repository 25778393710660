import React from "react"
import { Link } from "gatsby"


import Layout from "../components/layout"

import '../components/setup.css'
import '../components/elements/clock.css'

const Index = () => {
    return (
        <Layout>
            <main className="page__home">
                <div className="content">
                    <h1 className="clock">
                        <div className="clock__hand__one">
                        Cocktail
                        </div>
                        <div className="clock__hand__two">
                        International
                        </div>
                    </h1>
                </div>
            </main>
        </Layout>
    )
}

export default Index